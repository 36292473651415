

















































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
} from "@vue/composition-api";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import StickerCard from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBasket/components/StickerCard/StickerCard.vue";
import { userFacade } from "@/store/modules/user/user.facade";
import { i18n } from "@/locales/i18n";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import * as StickerService from "@/core/services/sticker.service";

export default defineComponent({
  name: "StickerSuccessPage",
  components: {
    StickerCard,
  },
  setup() {
    const transactionId = computed(stickerFacade.transactionId);
    const getDocumentLoading = computed(stickerFacade.getDocumentLoading);
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const cartHistoryQueryRow = computed(stickerFacade.cartHistoryQueryRow);
    let isUpdateProgress = false;
    const postCartHistoryQueryLoading = computed(
      stickerFacade.postCartHistoryQueryLoading
    );

    const navigatePurchase = () => {
      router.push(
        getLanguageStaticRoute(
          `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
        )
      );
    };
    const onDocumentDownload = (highwayStickerPurchaseId: string) => {
      stickerFacade.getDocument({ requestId: highwayStickerPurchaseId });
    };

    const getCartData = async () => {
      // await stickerFacade.getActiveCart();
      if (!notLoggedInUser.value) {
        await stickerFacade.postCartHistoryQuery({
          queryParams: {
            transactionId: transactionId.value as string,
          },
          paginationParams: {
            rows: 100,
            page: 1,
          },
        });
      } else {
        await stickerFacade.getAdhocPaymentResult();
      }
    };

    const cartDataInterval = setInterval(() => {
      if (notLoggedInUser.value) {
        updateCartData();
      } else {
        clearInterval(cartDataInterval);
      }
    }, 10000);

    const updateCartData = () => {
      getCartData().then(
        () => {
          // console.log(cartHistoryQueryRow.value);
        },
        () => {
          isUpdateProgress = true;
          clearInterval(cartDataInterval);
          router
            .push(
              getLanguageStaticRoute(
                `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
              )
            )
            .catch((err) => err);
        }
      );
    };
    onMounted(() => {
      getCartData();
    });
    onUnmounted(() => {
      clearInterval(cartDataInterval);
      if (!isUpdateProgress) {
        StickerService.removeAdHocCustomer();
        stickerFacade.resetCardStatus();
        stickerFacade.resetCart();
      }
    });
    return {
      notLoggedInUser,
      cartHistoryQueryRow,
      getDocumentLoading,
      postCartHistoryQueryLoading,
      onDocumentDownload,
      navigatePurchase,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("STICKER_PURCHASE_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
