


































































































































import { defineComponent, watch, ref, computed } from "@vue/composition-api";
import { i18n } from "@/locales/i18n";
import { EStickerTypeValue } from "@/core/dataTypes/enum";
import dialogService from "@/core/services/dialog.service";
import { DialogResultType } from "@/core/dataTypes/types";
import { isFuture } from "date-fns";

export default defineComponent({
  name: "StickerCard",
  props: {
    successfulness: Boolean,
    cartElement: Object,
    loading: Boolean,
    isAdHoc: Boolean,
    isValidSticker: Boolean,
    isDashboardStyle: Boolean,
  },
  setup(props, { emit }) {
    const isLocalLoading = ref(false);
    watch(
      () => props.cartElement,
      () => {
        isLocalLoading.value = false;
      }
    );

    watch(
      () => props.loading,
      (value) => {
        if (isLocalLoading.value) {
          isLocalLoading.value = value;
        }
      }
    );
    const isToday = computed(() =>
      isFuture(new Date(props.cartElement?.validityStart))
    );

    const onDocumentDownload = (highwayStickerPurchaseId: string) => {
      isLocalLoading.value = true;
      emit("onDocumentDownload", highwayStickerPurchaseId);
    };
    const clickDelete = (cartElementId: string) => {
      dialogService
        .confirmDialog({
          maxWidth: 424,
          componentProps: {
            title: i18n.tc("STICKER_CARD_DELETE_DIALOG.TITLE"),
            content: i18n.tc("STICKER_CARD_DELETE_DIALOG.SUBTITLE"),
            submitText: i18n.tc("STICKER_CARD_DELETE_DIALOG.BUTTONS.SUBMIT"),
            cancelText: i18n.tc("STICKER_CARD_DELETE_DIALOG.BUTTONS.CANCEL"),
          },
        })
        .$on("dialogResult", (result: DialogResultType): void => {
          if (result.payload) {
            isLocalLoading.value = true;
            emit("deleteCard", cartElementId);
          }
        });
    };

    return {
      isToday,
      EStickerTypeValue,
      isLocalLoading,
      clickDelete,
      onDocumentDownload,
    };
  },
});
